.alert {
  position: relative;
  color: #000;
  text-align: left;
  margin-top: 15px;
  padding: 11px 50px 11px 48px;
  @media (min-width: $grid-float-breakpoint-max) {
    padding: 11px 50px 11px 82px;
    margin-bottom: -38px;
  }
  &:before {
    font-family: "icomoon";
    content: "\e903";
    position: absolute;
    top: 10px;
    left: 15px;
    font-size: 18px;
    @media (min-width: $grid-float-breakpoint-max) {
      font-size: 32px;
      top: 5px;
      left: 25px;
    }
  }
  h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}

.alert-success {
  background-color: $tasman-alert-green;
  border-top: 1px solid $tasman-alert-green-border;
  border-bottom: 1px solid $tasman-alert-green-border;
  &:before {
    content: "\e903";
    color: $tasman-alert-green-border;
  }
}
.alert-warning {
  background-color: $tasman-alert-yellow;
  border-top: 1px solid $tasman-alert-yellow-border;
  border-bottom: 1px solid $tasman-alert-yellow-border;
  padding: 18px 0 30px 80px;
  &:before {
    content: "\e900";
    color: $tasman-alert-yellow-border;
  }
}
.alert-error {
  background-color: $tasman-alert-red;
  border-top: 1px solid $tasman-alert-red-border;
  border-bottom: 1px solid $tasman-alert-red-border;
  &:before {
    content: "\e90c";
    color: $tasman-alert-red-border;
  }
}
.alert-info {
  background-color: $tasman-alert-blue;
  border-top: 1px solid $tasman-alert-blue-border;
  border-bottom: 1px solid $tasman-alert-blue-border;
  &:before {
    content: "\e909";
    color: $tasman-alert-blue-border;
  }
}

.alert-close {
  width: 16px;
  height: 16px;
  opacity: 1 !important;
  position: absolute;
  right: 10px;
  &:before {
    position: absolute;
    top: 2px;
    left: 2px;
    font-family: "icomoon";
    content: "\e90d";
    font-size: 12px;
    color: #212121;
  }
}

.banner-page-alert {
  .alert {
    @media (min-width: $grid-float-breakpoint-max) {
      margin-bottom: 30px;
    }
  }
  
  a {
      text-decoration: underline;
  }
}

.page-results > .alert {
    margin-bottom: 30px;
}