.banner {
  position: relative;
  height: 616px;
  background-size: cover;
  background-position: center center;
  @media (max-width: $grid-float-breakpoint-max) {
    height: 384px;
  }
}

.banner-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.banner-content-desktop {
  position: absolute;
  width: 100%;
  display: none;
  @media (min-width: $grid-float-breakpoint-max) {
    display: block;
  }
}

/* Working as intended */
.banner-content-mobile {
  @media (min-width: $grid-float-breakpoint-max) {
    display: none;
  }
}
