.hero-image {
  background-color: #eee;
}

.hero-image img {
  display: block;
  margin: 0 auto;
}

.hero-container {
  @media (min-width: 1600px) {
    width: 1400px;
  }
}

.hero-content-padding {
  @media (min-width: 480px) {
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 75px;
    padding-right: 75px;
  }
}

.hero-button {
  margin-top: 15px;
  padding: 15px 20px 15px 20px;
  background-color: #eee;
  text-decoration: none !important;
  display: inline-block;
}

.hero-button-text-White {
  color: #fff;
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: #fff;
  };
}

.hero-button-text-Black {
  color: #000;
  &:hover,
  &:visited,
  &:link,
  &:active {
    color: #000;
  };
}

.silverstripe__elementalbannerblock__block__bannerblock {
  max-height: none !important;
  height: auto !important;
}

.hero-boxed-iframe {
  padding: 40px 0 32px 0;
}

.hero-page-quote {
  font-size: 40px;
  display: inline-block;
  padding: 2px 0px 2px 0px;
  margin-bottom: 20px;
  border-top: 2px solid #1d4798;
  border-bottom: 2px solid #1d4798;
}

.hero-page-source {
  font-style: italic;
  font-size: 12px;
}

.hero-page-emphasis-one {
  background-color: #222;
  display: inline-block;
  padding: 8px 16px 8px 16px;
  color: #fff;
}

.hero-page-emphasis-two {
  background-color: #eee;
  display: inline-block;
  padding: 8px 16px 8px 16px;
}

.banner-element__call-to-action {
  color: #fff !important;
  background-color: #1d4798 !important;
  text-decoration: none !important;
  padding: 15px 20px 15px 20px !important;
}

.hero-banner-image-wrapper {
  background-color: #eee;
  width: 100%;
  text-align: center;
}

.hero-banner-image-container {
  position: relative;
  @media (min-width: 1600px) {
    width: 1400px;
  }
}

.hero-banner-image-content {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  padding: 20px;
  color: #fff;
  h2 {
    color: #fff;
    margin-top: 0;
  }
}

.text-colour-White {
  color: #fff;
  h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

.text-colour-Black {
  color: #000;
  h1, h2, h3, h4, h5, h6 {
    color: #000;
  }
}
