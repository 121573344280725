body:not([class=UserDefinedForm]) {
  // Formfields
  .control-label {
    font-weight: $headings-font-weight;
  }

  .help-block {
    color: $text-muted;
  }

  // Userforms
  div {
    .field {
      @extend .form-group;
    }
  }
  .middleColumn {
    .text,
    .textarea {
      @extend .form-control;
    }
  }
  .Actions {

    #ForgotPassword {
      margin-top: 20px;
    }

    input[name="action_process"] {
        @extend .btn;
        @extend .btn-success;
    }
    input[name="action_clearForm"] {
        @extend .btn;
        @extend .btn-warning;
        background-color: #f7bb24;
        color: #000;
    }
  }

  .optionset {
    list-style: none;
    padding-left: 0;
    margin: 0;
    .checkbox,
    .radio {
      margin-left: -20px;
      display: inline;
      position: relative;
      top: 1px;
    }
    .odd,
    .even {
      label {
        padding-left: 20px;
      }
    }
  }
  .error.message {
    @extend .help-block;
  }
  .resetformaction {
    @extend .btn;
    @extend .btn-default;
  }

  #popovers,
  #tooltips {
    .btn {
      margin-right: 5px;
      float: left;
    }
  }
  // .checkbox input[type="checkbox"] {
  //   margin-left: 0;
  // }

  .Actions .action {
    margin-bottom: 5px !important;
  }

  #MemberLoginForm_LoginForm,
  #LDAPLoginForm_LoginForm,
  .userform {
    max-width: 600px !important;
  }

  #MemberLoginForm_LoginForm_action_doLogin,
  #LDAPLoginForm_LoginForm_action_doLogin {
    @extend .btn;
    @extend .btn-success;
    margin-bottom: 20px;
  }

  #ForgotPassword {
    clear: both;
  }

  .error.message {
    color: red !important;
  }


  .date-alt {
    label {
      font-weight: 400;
    }
  }

}
