.alert-message-civil {
  width: 100%;
  background-color: $tasman-yellow;
  padding: 18px 0 28px 0;
}

.alert-message-civil-content {
  position: relative;
  padding-left: 80px;
  p {
    margin-bottom: 0;
  }
  a {
    color: #212121;
    text-decoration: underline;
  }
}

.civil-defence-logo {
  position: absolute;
  top: 0;
  left: 0;
}

.civil-defence-heading {
  font-size: 16px;
  margin-bottom: 5px;
}
