body.UserDefinedForm {
  .userform-fields {
    .checkbox {

      input[type="checkbox"] {
        position: absolute;
        margin-left: -20px;
      }
    }

    .userform {
      legend {
        font-size: 18px;
      }
    }
  }
}
