.site-footer {
  background-color: $tasman-blue;
  color: #fff;
  padding: 32px 0 45px 0;
  background-image: url('/_resources/themes/watea/images/footer-image.png');
}

.footer-heading {
  font-size: 16px;
  color: #fff !important;
  margin-bottom: 11px;
}

.footer-contact-us {
  list-style: none;
  padding: 0;
  a {
    color: #fff;
    text-decoration: underline;
  }
}

.footer-social-media {
  list-style: none;
  padding: 0;
  a:hover,
  a:visited {
    text-decoration: none;
    color: transparent;
  }
  li {
    a {
      &:before {
        font-family: 'icomoon';
        color: #fff;
        font-size: 32px;
      }
    }
    a:hover {
      &:before {
        color: #F7BB24;
      }
    }
  }
  .facebook {
    float: left;
    display: block;
    margin-right: 15px;
    &:before {
      content:'\e90b';
    }
  }
  .twitter {
    float: left;
    display: block;
    margin-right: 15px;
    &:before {
      content:'\e901';
    }
  }
  .linkedin {
    float: left;
    display: block;
    margin-right: 15px;
    &:before {
      content:'\eac9';
    }
  }
  .instagram {
    float: left;
    display: block;
    &:before {
      content:'\ea92';
    }
  }
}

.footer-underline {
  background-color: $tasman-lightblue;
  display: block;
  width: 40px;
  height: 2px;
  margin-bottom: 16px;
}

.footer-womens-refuge-button {
  float: right;
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: transparent;
  padding: 0;
  border: 0;
}

.footer-tasman-logo {
  float: right;
  clear: right;
  margin-bottom: 20px;
}

.footer-newzealand-government-logo {
  float: right;
  clear: right;
  img {
    max-width: 150px;
  }
}

.footer-section {
  clear: both;
  width: 100%;
  float: left;
  display: block;

  p a {
    text-decoration: underline;
  }

  @media (max-width: 980px) {
    text-align: center;
    .footer-womens-refuge-button {
      float: none;
      display: inline-block;
      clear: both;
    }
    .footer-tasman-logo {
      float: none;
      clear: both;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .footer-newzealand-government-logo {
      display: inline-block;
      float: none;
    }
    p {
      max-width: 360px;
      margin: 0 auto;
      margin-bottom: 18px;
    }
    .footer-underline {
      display: inline-block;
    }
    .footer-social-media {
      display: inline-block;
      width: 128px;
      clear: both;
    }
  }
}
