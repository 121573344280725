@media (max-width: $grid-float-breakpoint-max) {
  .navbar-toggle {
    padding: $padding-large-vertical $padding-base-horizontal;
    margin-right: 0;
  }
}

// Override BS default behavior
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover {
  background-color: #f7f7f7;
}

.main-nav {
  .navbar {
    z-index: $zindex-navbar;
  }

  .nav-item.current > a {
    background-color: transparent;
    color: #000;
    @media (max-width: $grid-float-breakpoint-max) {
      color: #000;
    }
    &:focus,
    &:hover {
      background-color: transparent;
    }
  }

  // Change caret icon for dropdown to .fa-angle-down
  .fa-caret-down:before {
    font-family: 'icomoon';
    font-weight: 700;
    font-size: 14px;
    content: "\E910";
    position: absolute;
    top: 15px;
    right: 18px;
  }

  // Change caret icon for active dropdown to .fa-angle-up
  .fa-caret-up:before {
    display: none;
  }

  .dropdown {
    &:hover .navbar-touch-caret,
    &:focus .navbar-touch-caret {
      background-color: transparent;
    }

    // When active
    &.current {
      .navbar-touch-caret,
      &:hover .navbar-touch-caret,
      &:focus .navbar-touch-caret {
        background-color: transparent;
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }
  }

  // Mobile only
  @media (max-width: $grid-float-breakpoint-max) {

    .navbar {
      margin-bottom: 0 !important;
    }

    .navbar-nav {
      margin-top: 0;
      margin-bottom: 0;
    }

    .navbar-collapse {
      box-shadow: none;
    }

    .nav-item {
      border-bottom: 1px solid #f2f2f2;
    }

    .dropdown-menu-item {
      border-bottom: 1px solid #f2f2f2;
    }

    .dropdown-menu-item:last-child {
      border-bottom: 3px solid $tasman-yellow;
    }

    // Dropdown
    .dropdown {
      // Change caret direction pointing up

      &.open .navbar-touch-caret .fa-caret-down:before {
        font-family: 'icomoon';
        font-weight: 700;
        font-size: 14px;
        content: "\E912";
      }

      .navbar-touch-caret {
        color: #000;
        padding: 10px 25px 10px 25px;
      }

      .dropdown-menu {
        padding: 0;
        // Requires nesting
        background-color: #f7f7f7;
      }
    }

    .open .dropdown-menu > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  // Desktop only
  @media (min-width: $grid-float-breakpoint-max) {

    .dropdown.open {
      button {
        background-color: #f7f7f7 !important;
        &:hover {
          background-color: #f7f7f7 !important;
        }
      }
    }

    .nav-item > a {
      padding-top: $navbar-padding-vertical;
      padding-bottom: $navbar-padding-vertical;
    }

    // 1st level
    .nav-item {

      border-bottom: $nav-hover-border-width solid transparent;
      transition: border-color 300ms ease-out;
      transition: background-color 300ms ease-out;

      &:focus,
      &:hover {
        .dropdown-menu {
          display: block;
        }
        border-bottom-color: $tasman-lightblue;
        background-color: #fff;
      }

      > .link {
        &:focus,
        &:hover {
          color: red;
        }
      }

      > .section {
        color: #000 !important;
      }

      > .current {
        background-color: transparent !important;
        &:focus,
        &:hover {
          color: #000 !important;
        }
      }

      > .dropdown-menu {
        margin-top: 4px;
        box-shadow: none;
        border: 0;
        padding: 0;
      }

    }

    // 1st level with dropdown
    .dropdown {
      // Allow space for caret
      // padding-right: 0px; // 6px approx half width of icon

      > .link {
        &:focus,
        &:hover {
          border-bottom-color: $navbar-default-brand-hover-bg;
        }
      }

      &.current .navbar-touch-caret {
        border-bottom: $nav-hover-border-width solid transparent;
      }
    }

    .fa-caret-down:before {
      top: 18px;
      right: 8px;
    }

    .dropdown-menu-item a {
      margin: 0;
      padding: 10px 15px 10px 15px;
    }

  }
}

.navbar-toggle {
  position: relative;
  width: 42px;
  height: 42px;

  .icon-bar {
    height: 3px;
    margin-bottom: 6px;
    background-color: $tasman-blue;
    margin-top: -6px;
    display: none;
  }

  &:before {
    position: absolute;
    bottom: -12px;
    left: 4px;
    content: "menu";
    font-size: 12px;
    color: $tasman-blue;
    display: none;
  }

  &:after {
    position: absolute;
    top: -5px;
    left: 4px;
    font-size: 34px;
    color: $tasman-blue;
    font-family: 'icomoon';
    content: "\e90d";
  }

}

.navbar-toggle.collapsed {

  .icon-bar {
    display: none;
  }

  &:before {
    display: block;
  }

  &:after {
    content: "\e908";
  }
}

// Change caret icon for dropdown to .fa-angle-down
.fa-caret-down:before {
  content: "\F107";
}

// Change caret icon for active dropdown to .fa-angle-up
.fa-caret-up:before {
  content: "\F106";
}

.navbar-default .navbar-nav > li > a {
  color: #000;
  &:hover,
  &:focus {
    color: #000;
  }
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: transparent;
  color: #000;
}

.dropdown-menu > .active > a:hover {
  background-color: #f5f5f5;
}

.dropdown-menu > .active > a:focus {
  background-color: transparent;
  color: #000;
}

