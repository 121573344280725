.landing-page {
  border-bottom: 1px solid #001F2C;
  padding: 30px 0 40px;
  h2 {
    margin-top: 0;
  }
}

.landing-page-title {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

.landing-page-intro {
  font-size: 24px;
}

.landing-page-banner {
  width: 100%;
  min-height: 350px;
  background-color: #f2f2f2;
}

.landing-page-banner-container {
  position: relative;
  @media (min-width: 1200px) {
    max-width: 1440px;
    margin: 0 auto;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    .container {
      padding: 0;
    }
  }
}

.landing-page-banner-intro {
  @media (min-width: 800px) {
    float: left;
    width: 50%;
    padding-right: 30px;
  }
  @media (min-width: 1200px) {
    width: 445px;
  }
  h1 {
    margin-top: 85px;
    font-size: 40px;
    font-weight: 700;
    color: $tasman-blue;
  }
  p {
    font-size: 24px;
    line-height: 30px;
    color: #212121;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    padding: 0 15px 0 15px;
    h1 {
      margin-top: 40px;
      font-size: 28px;
    }
    p {
      font-size: 18px;
      line-height: 24px;
      color: #212121;
      margin-bottom: 28px;
    }
  }
}

.landing-page-banner-image {
  position: relative;
  float: left;
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center center;
  @media (min-width: 800px) {
    float: left;
    height: 350px;
    width: 50%;
  }
  @media (min-width: 1200px) {
    position: absolute;
    right: 0;
    height: 350px;
  }
}

.page-summary {
  position: relative;
  float: left;
  width: 100%;
  display: block;
  padding: 52px 0px 44px 0px;
  border-top: 2px solid #f2f2f2;
  &:before {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100px;
    content: ' ';
    border-top: 2px solid $tasman-lightblue;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 33px 0px 33px 0px !important;
  }
}

.page-summary-title {
  float: left;
  width: 50%;
  h2 {
    color: $tasman-blue;
    font-weight: 700;
    font-size: 32px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    width: 100%;
    h2 {
      font-size: 24px;
    }
  }
}

.page-summary-content {
  float: left;
  width: 50%;
  h3 {
    margin-top: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    width: 100%;
  }
}

.page-summary-page-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  li {
    display: block;
    float: left;
    width: 50%;
    margin-bottom: 5px;
    @media (max-width: $grid-float-breakpoint-max) {
      width: 100%;
    }
  }
  a {
    text-decoration: none;
    color: #222;
    font-size: 20px;
    &:before {
      font-family: 'icomoon';
      content: '\e90e';
      margin-right: 6px;
      position: relative;
      top: 2px;
    }
    @media (max-width: $grid-float-breakpoint-max) {
      font-size: 18px !important;
    }
  }
}

.page-summary-page-item {
  float: left;
}

.page-summary-title a {
  text-decoration: none;
}

.row.content-area {
    margin-top: 30px;
    margin-bottom: 30px;
}
