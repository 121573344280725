.search-tabs {
  li {
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    a {
      border-radius: 0;
      text-decoration: none;
      margin-right: 0;
      margin-bottom: -1px;
      &:hover,
      &:focus {
        cursor: pointer !important;
        border-bottom: 1px solid $tasman-yellow;
      }
    }
  }
  .active {
    a {
      border-color: $tasman-yellow !important;
      border-bottom: 0 !important;
      border-radius: 0;
    }
  }
  border-bottom: 1px solid $tasman-yellow;
  margin-bottom: 20px;
}
