.news-events-article .update-notice-label {
  color: #fff;
}
.news-page-header {
  margin-bottom: 18px;
}
.news-page-heading {
  margin-bottom: 0;
}
.update-notice-label {
  background-color: #1d4798;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}
.update-notice {
  width: 100%;
  background-color: $tasman-alert-blue;
  border-top: 1px solid $tasman-alert-blue-border;
  border-bottom: 1px solid $tasman-alert-blue-border;
  margin-bottom: 20px;
  position: relative;
  padding: 11px 110px 11px 15px;
  h5 {
    margin-bottom: 6px;
  }
  @media (min-width: $grid-float-breakpoint-max) {
    padding: 11px 110px 11px 15px;
  }
  @media (max-width: 460px) {
    padding: 11px 15px 11px 15px;
  }
  &:before {
    font-family: "icomoon";
    content: "\e900";
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    color: #1d4798;
    @media (min-width: $grid-float-breakpoint-max) {
      font-size: 32px;
      top: 15px;
      right: 25px;
    }
    @media (max-width: 460px) {
      display: none;
    }
  }
}
.filtered-update-title {
  display: inline-block;
}
