@media (min-width: 1200px) {
  .campaign-cards-container {
    width: 1170px;
  }
  .campaign-cards-gutter > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 1199px) {
  .campaign-cards-container {
    width: 750px;
  }
  .campaign-cards-gutter > [class*='col-'] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .campaign-cards-container {
    width: 100%;
  }
}

@media (max-width: 290px) {
  .campaign-cards-container {
    width: 100%;
  }
}

.campaign-cards {
  padding: 95px 0 75px 0;
  background-color: $tasman-gray;
  @media (max-width: $grid-float-breakpoint-max) {
    padding: 60px 0 45px 0;
  }
}

.campaign-card {
  margin-bottom: 30px;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 #d8d8d8, 0 -2px 5px 0 rgba(216, 216, 216, 0.5);
  h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px;
  }
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(33, 33, 33, 0.3);
  }
}

.campaign-card-image {
  img {
    width: 100%;
    height: auto;
  }
}

.campaign-card-content {
  position: relative;
  padding: 20px 20px 50px 20px;
}

.campaign-card-link {
  position: absolute;
  bottom: 20px;
  text-decoration: none !important;
  color: #000;
  &:hover {
    color: #000;
  }
  &:after {
    position: relative;
    top: 2px;
    margin-left: 6px;
    font-family: 'icomoon';
    content: "\e90e";
  }
}
