.online-actions {
  padding: 32px 0 32px 0;
  background-color: $tasman-blue;
}

.online-actions-btn {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 12px;
  color: #fff;
  text-decoration: none !important;
  font-size: 18px;
  position: relative;
  padding: 0 0 0 32px;
  span {
    border-bottom: 2px solid #fff;
    padding: 0 0 6px 0;
  }
  &:focus,
  &:hover {
    color: #fff;
    span {
      border-bottom: 2px solid #fff;
    }
  }
  &:before {
    left: 0;
    top: 0;
    position: absolute;
    font-family: 'icomoon';
    font-size: 26px;
  }
}

.btn-apply,
.btn-pay {
  margin-right: 42px;
  @media (max-width: $grid-float-breakpoint-max) {
    margin-right: 0;
  }
}

.btn-apply {
  &:before {
    content:"\e905";
  }
  span {
    border-bottom: 2px solid $tasman-lightgreen;
  }
}
.btn-pay {
  &:before {
    content:"\e902";
  }
  span {
    border-bottom: 2px solid $tasman-lightblue;
  }
}
.btn-track {
  &:before {
    content:"\e90f";
  }
  span {
    border-bottom: 2px solid $tasman-purple;
  }
}

.online-call-to-action {
  float: left;
  font-weight: 600;
  font-size: 28px;
  margin-right: 70px;
  line-height: 45px;
  color: #fff;
  @media (max-width: $grid-float-breakpoint-max) {
    width: 100%;
    text-align: center;
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.online-actions-container {
  max-width: 620px;
  margin: 0 auto;
}

.online-action-calls {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  li {
    &.do-it-online-link {
      width: 7em;
    }
    display: inline-block;
  }
  @media (max-width: $grid-float-breakpoint-max) {
    clear: both;
    li {
      display: block;
      text-align: center;
      margin-bottom: 15px;
    }
  }
}
