// Watea theme specific variables and overrides
@import "./variables";
@import "./variables/variables";

// Starter theme styles
@import "../../../starter/src/scss/main";

// Watea theme mixins
@import "./utils/mixins";

// Watea theme styles
@import "./typography";
@import "./components/carousel";
@import "./components/footer";
@import "./components/forms";
@import "./components/header";
@import "./components/nav";
@import "./components/pages";

// TDC styles
@import "./pages/landing-page";
@import "./pages/news-and-events-page";
@import "./pages/userforms";
@import "./components/banner";
@import "./components/banner-popular-links";
@import "./components/page-banner";
@import "./components/breadcrumbs";
@import "./components/online-actions";
@import "./components/cards";
@import "./components/home-links";
@import "./components/search";
@import "./components/search-tabs";
@import "./fonts/type";
@import "./fonts/icons";
@import "./components/civil-defence-message";
@import "./components/alert-messages";
@import "./components/environmental";
@import "./components/elemental";
@import "./components/update-notice";

// Elemental styles
@import "./components/accordion";
@import "./pages/hero-page";
