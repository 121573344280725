.page-banner {
  width: 100%;
  min-height: 250px;
  background-color: #f2f2f2;
  padding: 60px 0 60px 0;
  h1 {
    color: $tasman-blue;
    margin-top: 0;
    font-weight: 700;
    font-size: 28px;
    @media (min-width: $grid-float-breakpoint-max) {
      font-size: 40px;
    }
  }
}

.page-banner-no-intro {
  min-height: auto;
  h1 {
    color: $tasman-blue;
    margin-top: 0;
    font-weight: 700;
    font-size: 28px;
    @media (min-width: $grid-float-breakpoint-max) {
      font-size: 40px;
    }
  }
}

.page-banner-intro {
  font-size: 24px;
  line-height: 30px;
}

main {
  .page {
    padding: 60px 0 60px 0;
  }
}
