// Colours - Primary
$tasman-blue: #1d4798;
$tasman-yellow: #f7bb24;
$tasman-yellow-hover: #f8ce63;
$tasman-gray: #f2f2f2;
$tasman-lightgreen: #a3ce56;
$tasman-lightblue: #6bccdb;
$tasman-lightblue-hover: #94dae5;
$tasman-purple: #6b63aa;

// Colours - Alerts
$tasman-alert-green: #F8FBF3;
$tasman-alert-green-border: #a3ce56;
$tasman-alert-yellow: #FEFAF0;
$tasman-alert-yellow-border: #f7bb24;
$tasman-alert-red: #FDF2F1;
$tasman-alert-red-border: #e74c3c;
$tasman-alert-blue: #EFF2F8;
$tasman-alert-blue-border: #1d4798;
