.site-header-search {
  font-size: 14px;
  margin-top: 15px;
  float: right;
  display: none;
  @media (min-width: $grid-float-breakpoint-max) {
    display: block;
  }
}

.site-header-search-input {
  float: left;
  background-color: #fff;
  border: 0;
  border-bottom: 1px solid $tasman-lightblue;
  height: 40px;
  width: 250px;
  padding: 0 0 0 10px;
  color: #000;
}

.site-header-search-input::placeholder {
  color: #000;
}

.site-header-search-btn {
  float: left;
  border: 0;
  background-color: $tasman-lightblue;
  padding: 8px 15px 8px 15px;
  color: #000;
  margin-left: 14px;
  margin-top: 3px;
  transition: background-color 300ms ease-out;
  &:hover {
    background-color: $tasman-lightblue-hover;
  }
}

.site-header-search-mobile {
  margin: 6.5px -5px;
  font-size: 14px;
  .site-header-search-input {
    width: 219px;
    @media (max-width: 345px) {
      width: 205px;
      padding: 0 0 0 5px;
    }
  }
  .site-header-search-btn {
    padding: 10px 10px 9px 10px;
    margin-top: 0px;
    @media (max-width: 345px) {
      margin-left: 5px;
    }
  }
  @media (min-width: $grid-float-breakpoint-max) {
    display: none;
  }
}

.search-results-title {
  margin-bottom: 0;
  a {
    color: $tasman-blue;
    &:before {
      font-size: 20px;
      padding-right: 4px;
    }
  }
}

.results {
  padding-bottom: 0px;
  border-bottom: 2px solid #ccc;
}

.result {
  margin-bottom: 25px;
}
