@font-face {
  font-family: 'icomoon';
  src:  url('/_resources/themes/watea/fonts/tdcicons/fonts/icomoon.eot?qit888');
  src:  url('/_resources/themes/watea/fonts/tdcicons/fonts/icomoon.eot?qit888#iefix') format('embedded-opentype'),
    url('/_resources/themes/watea/fonts/tdcicons/fonts/icomoon.ttf?qit888') format('truetype'),
    url('/_resources/themes/watea/fonts/tdcicons/fonts/icomoon.woff?qit888') format('woff'),
    url('/_resources/themes/watea/fonts/tdcicons/fonts/icomoon.svg?qit888#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-external-link:before {
  content: "\e911";
}
.icon-warning:before {
  content: "\e900";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-track:before {
  content: "\e902";
}
.icon-success:before {
  content: "\e903";
}
.icon-pdf:before {
  content: "\e904";
}
.icon-pay:before {
  content: "\e905";
}
.icon-microsoft-word:before {
  content: "\e906";
}
.icon-microsoft-excel:before {
  content: "\e907";
}
.icon-menu:before {
  content: "\e908";
}
.icon-info:before {
  content: "\e909";
}
.icon-google:before {
  content: "\e90a";
}
.icon-facebook:before {
  content: "\e90b";
}
.icon-error:before {
  content: "\e90c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-arrow:before {
  content: "\e90e";
}
.icon-apply:before {
  content: "\e90f";
}
.icon-accordian:before {
  content: "\e910";
}
