// Header can also be inside articles or sections so limit to site header
body > header {
  color: $navbar-default-color;
}

// Language selector
.language-selector {
  text-transform: capitalize;

  .dropdown-toggle {
    @include form-controls-style();
    text-transform: capitalize;
    padding: $padding-base-horizontal 15px;
  }

  .dropdown-toggle:focus,
  &.open .dropdown-toggle {
    box-shadow: none;
    border-color: $dropdown-bg;
    background-color: $navbar-default-link-active-color;

    // Requires nesting
    &.btn {
      background-color: $navbar-default-link-active-color;
      color: $link-color;
    }

    &:hover,
    &:active {
      background-color: $navbar-default-link-active-color;
      color: $link-color;
      box-shadow: none;
      border-color: $dropdown-bg;
    }
  }

  .dropdown-menu {
    border-color: $brand-primary;
    margin-top: 0;

    // Show focus on all links (override BS)
    > .active > a:focus {
      @include outline-focus();
    }
  }
}

// These must be separate to work
.site-header-search .form-control::-webkit-input-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control:-moz-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control::-moz-placeholder {
  color: $navbar-default-link-active-color;
}

.site-header-search .form-control:-ms-input-placeholder {
  color: $navbar-default-link-active-color;
}

// Main site header search
.site-header-search {

  .form-control,
  button[type="submit"] {
    height: $navbar-height;
    @include form-controls-style();

    &:focus {
      @include outline-focus();
    }
  }

  .form-control {
    box-shadow: none;
    padding: $padding-large-vertical $padding-large-horizontal;
    height: ($line-height-computed + ($padding-large-vertical * 2) + 2);
  }

  // Search button hover
  .btn {
    padding: $padding-large-vertical $padding-large-horizontal;

    &:active:hover,
    &:active:focus,
    &:active,
    &:focus,
    &:hover {
      background-color: $brand-primary;
      color: $btn-primary-color;
      border-color: $brand-primary;
    }
  }
}

// Branding dimension restrictions
.site-header-brand img {
  /*
  max-height: $line-height-computed * 6;
  max-width: $line-height-computed * 10;
  width: auto;
  */
}

// Accessibilty links to navigation
.skip-links a {
  color: $navbar-default-link-color;
  background: $navbar-default-bg;
}

.site-header {
  padding-bottom: 14px;
}

// Mobile only
// Site header
@media (max-width: $grid-float-breakpoint-max) {
  .site-header {

    padding-top: 18px;
    padding-bottom: 22px;

    .site-header-brand {
      margin-top: -5px;
      font-size: 1.8em;
    }

    .navbar-toggle,
    .language-selector {
      position: absolute;
      top: $line-height-computed * 1.2;
    }

    .navbar-toggle {
      right: $line-height-computed * 0.6;
      padding: 17px 10px 15px 10px;
      margin-top: -10px;
    }

    .language-selector {
      right: $line-height-computed * 2.8;
    }
  }
}

.header-holder {
  position: relative;
}

.header {
  border-top: 8px solid $tasman-blue;
  @media (min-width: 767px) {
    width: 100%;
    .navbar {
      margin-bottom: 0;
      background-color: transparent !important;
    }
    .link,
    .pull-right {
      color: #000 !important;
    }
  }
}

.home-header {
  background-color: rgba(255, 255, 255, 0.82);
  width: 100%;
  @media (min-width: 767px) {
    position: absolute;
    top: 0;
    z-index: 1;
  }
}

.site-header-logo-desktop {
  @media (max-width: 990px) {
    width: 320px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.site-header-logo-mobile {
  width: 100%;
  @media (min-width: 767px) {
    display: none;
  }
}
