.home-page-links {
    padding: 30px 0 40px 0;
    h3 {
        margin-top: 0;
    }
}
.home-links {
    list-style: none;
    padding-left: 0;
}
