.element-accordion-title {
  padding: 22px 55px 35px 15px;
  letter-spacing: -0.3px;
  font-size: 20px !important;
  color: #212121 !important;
  margin-bottom: 0;
  border-top: 1px solid $tasman-lightblue;
  position: relative;
  .fa {
    position: absolute;
    right: 20px;
    top: 28px;
    font-weight: bold;
    pointer-events: none;
  }
  @media (min-width: $grid-float-breakpoint-max) {
    padding: 22px 55px 35px 30px;
    .fa {
      right: 30px;
    }
  }
}

.element-accordion {
  margin: 0 -20px 0 -20px;
  @media (min-width: $grid-float-breakpoint-max) {
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    color: #212121 !important;
  }
}

.element-accordion-open {
  background-color: #f7f7f7;
}

.content-element__content {
  padding: 0px 55px 35px 15px;
  @media (min-width: $grid-float-breakpoint-max) {
    padding: 0px 55px 35px 30px;
  }
}
