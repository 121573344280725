.banner-popular-links {
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 15px;
  list-style: none;
  position: relative;
  margin-top: 32px;
  @media (min-width: $grid-float-breakpoint-max) {
    margin-top: 55px;
  }
}

.popular-item {
  float: left;
  clear: both;
  margin-right: 5px;
  margin-bottom: 5px;
  @media (max-width: 300px) {
    width: 100%;
  }
}

.popular-link {
  display: block;
  padding: 12px 20px 12px 20px;
  background-color: $tasman-lightblue;
  text-decoration: none !important;
  color: #000;
  transition: background-color 300ms ease-out;
  &:hover {
    color: #000;
    background-color: $tasman-lightblue-hover;
  }
}
