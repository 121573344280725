/* work-sans-regular - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Work Sans'), local('WorkSans-Regular'),
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-regular.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-600 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-600.svg#WorkSans') format('svg'); /* Legacy iOS */
}
/* work-sans-700 - latin-ext_latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Work Sans Bold'), local('WorkSans-Bold'),
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/_resources/themes/watea/fonts/worksans/work-sans-v3-latin-ext_latin-700.svg#WorkSans') format('svg'); /* Legacy iOS */
}
